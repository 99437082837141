var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('body',{staticClass:"application application-offset"},[_c('div',{staticClass:"container-fluid container-application"},[_c('mainMenu'),_vm._v(" "),_c('div',{staticClass:"main-content position-relative"},[_c('mainNav'),_vm._v(" "),_c('div',{staticClass:"page-content index"},[_c('div',{staticClass:"page-title"},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-md-6 mb-3 mb-md-0",staticStyle:{"z-index":"5"}},[_c('h5',{staticClass:"h3 font-weight-400 mb-0 text-white"},[_vm._v("嗨，"+_vm._s(_vm.userName)+"!")]),_c('span',{staticClass:"text-sm text-white opacity-8"},[_vm._v("祝你有個美好的一天!")])])])]),_c('div',{staticClass:"card",staticStyle:{"z-index":"2"}},[_c('br'),_c('v-row',[_c('v-col',{staticClass:"px-10"},[_c('v-text-field',{attrs:{"label":"場域名稱","outlined":"","hide-details":""},model:{value:(_vm.jsName),callback:function ($$v) {_vm.jsName=$$v},expression:"jsName"}})],1),_c('v-col',{staticClass:"px-10"},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"狀態","dense":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"px-10"},[_c('v-btn',{staticClass:"mr-4 white--text",attrs:{"color":"#37906C"},on:{"click":function($event){return _vm.toCreate()}}},[_c('v-icon',[_vm._v("mdi-note-plus")]),_vm._v("新增 ")],1)],1)],1),_c('v-data-table',{staticClass:"mx-12",attrs:{"headers":_vm.headers,"items":_vm.datalist,"items-per-page":_vm.listPageSize,"height":"800px","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.del",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toDel(item)}}},[_vm._v("mdi-delete-forever")])]}},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toSelect(item)}}},[_vm._v("mdi-account-box")])]}},{key:"item.notify",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toNotificationSetting(item)}}},[_vm._v("mdi-message-cog")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toEdit(item)}}},[_vm._v("mdi-square-edit-outline")])]}},{key:"item.dashbord",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"title":"人員進出紀錄顯示"},on:{"click":function($event){$event.stopPropagation();return _vm.toDashbord(item)}}},[_vm._v("mdi-monitor-dashboard")]),_c('v-icon',{attrs:{"title":"熱指數、溫濕度顯示"},on:{"click":function($event){$event.stopPropagation();return _vm.toDashbordInfo(item)}}},[_vm._v("mdi-monitor-dashboard")])]}}],null,true)})],1)]),_c('mainFooter')],1)],1)]),_c('loadingMask',{staticStyle:{"z-index":"9999"},attrs:{"showLoading":_vm.isLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }