<template>
      <v-overlay z-index="0" :value="showLoading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="gray"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
</template>

<script>
export default {
  name: "loadingMask",
  props: ['showLoading'],
}
</script>